import { createApp } from 'vue'
import App from './App.vue'
const app= createApp(App)
import 'vue-select/dist/vue-select.css';
import state from './store/state'
import store from './store/store'
import axios from 'axios'
// import versionData from './version.json';
import router from './router.js'
import './assets/sass/index.sass'
import './style.css'
import 'intro.js/introjs.css';
import Vue3PersianDatetimePicker from 'vue3-persian-datetime-picker'
import Notifications from '@kyvg/vue3-notification'
import 'boxicons/css/boxicons.css'
import vSelect from 'vue-select'
import { createI18n } from 'vue-i18n'
import fa from './i18n/fa'
import en from './i18n/en'
import convertToEnglishDirective from './directives/convertToEnglish';
import vPreventMultipleClicks from './directives/v-prevent-multiple-clicks';
import './registerServiceWorker'
// import AddToHomescreen from '@owliehq/vue-addtohomescreen';

//hide console and other
// app.config.devtools = false;
// app.config.productionTip = false;
// app.config.errorHandler = () => {};
// app.config.warnHandler = () => {};
// console.log = function() {};
// console.warn = function() {};
// console.error = function() {};


//axios
axios.defaults.baseURL = state.serverBaseUrl + '/api'
axios.defaults.headers.common['Content-Type'] = 'Application/json'
axios.defaults.headers.common.Accept = 'Application/json'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')

//version
// const currentVersion = versionData.version;
// const savedVersion = localStorage.getItem('appVersion') ;
// if (currentVersion !== savedVersion) {
//   localStorage.setItem('appVersion', currentVersion);
//   if ('caches' in window) {
//       caches.keys().then((cacheNames) => {
//           cacheNames.forEach((cacheName) => {
//               caches.delete(cacheName);  // حذف کش‌ها
//             });
//         });
//     }
//     window.location.reload();
// }

//clarity
if (state.baseUrl === 'https://navgam.ir/') {
    const clarityScript = document.createElement('script');
    clarityScript.type = 'text/javascript';
    clarityScript.async = true;
    clarityScript.src = "https://www.clarity.ms/tag/olhnuuztvr";

    // اضافه کردن اسکریپت به تگ <head>
    document.head.appendChild(clarityScript);

    // فعال‌سازی Clarity
    window.clarity = window.clarity || function() {
        (window.clarity.q = window.clarity.q || []).push(arguments);
    };
}


const i18n = createI18n({
    locale: 'fa', // set the default locale
    messages: {
        fa,
        en
    }
})


router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token');
    if (!token) {
        if(to.path === '/Login' || to.path === '/register'){
            next()
        }
        else{
            next('/Login');
        }
    }
    else if(token && to.path === '/Login' || to.path === '/register'){
        next(from);
    }
    else if(token && to.path !== '/Login' && to.path !== '/register'){
        next();
    }
});


app.component('DatePicker', Vue3PersianDatetimePicker)
app.use (router)
app.use(i18n)
app.use(store)
app.use(Notifications)
app.component('v-select', vSelect)
app.directive('convert-to-english', convertToEnglishDirective);
app.directive('prevent-multiple-clicks', vPreventMultipleClicks);
// app.use(AddToHomescreen, {
//     buttonColor: '#115e59',
//   });
app.mount('#app')

